<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <h3>Configure your settings</h3>
        <hr />
        <CAlert v-if="message" :color="alertType" fade> {{ message }}</CAlert>
        <CRow>
          <CCol sm="6">
            <div><label>Change your logo</label></div>
            <input
              id="file"
              ref="file"
              type="file"
              @change="handleFileUpload"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" v-if="logoSrc">
            <br />
            <br />
            <div><label>Logo:</label></div>
            <div>
              <img
                :src="logoSrc"
                class="img-thumbnail"
                style="max-height: 200px"
              />
            </div>
          </CCol>
        </CRow>
        <br>
        <CRow>
          <CCol sm="6">
            <div><label>Options</label></div>
            <CMultiSelect
              v-if="partner"
              :multiple="true"
              :selection="true"
              v-model="selectedOptions"
              @update="appendValue($event)"
              placeholder="No options selected"
              optionsEmptyPlaceholder="No options"
              selectionType="text"
              :options="options"
              :selected="selectedOptions"
            />
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol col="9"></CCol>
          <CCol class="d-flex justify-content-end">
            <CButton color="primary" @click="save()">
              <CIcon name="cil-save" /> Save
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </transition>
</template>
<script>
import axios from "axios";

export default {
  name: "GuestGroups",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",

      // Delete
      guestGroup: [],
      updatePackage: {
        logo: null,
      },
      warningModal: false,
      partner: null,
      file: null,
      logoSrc: "",
      //List
      items: [],
      fields: ["status", "name", "EDIT", "DELETE"],
      options: [
        {
          value: "canModify",
          text: "Customers can modify bookings",
        },
        {
          value: "canCancel",
          text: "Customers can cancel bookings",
        },
        {
          value: "specialRequestCheckout",
          text: "Enable special requests in checkout"
        }
      ],
      selectedOptions: [],
      //Alert
      message: null,
      alertType: "danger",
    };
  },
  computed: {},
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    appendValue(event) {
      this.selectedOptions = event;
    },
    get() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/Partner/get-from-header`)
        .then(function (response) {
          if (response.data.customerCanModifyBookings)
            self.selectedOptions.push("canModify");
          if (response.data.customerCanCancelBookings)
            self.selectedOptions.push("canCancel");
          if(response.data.specialRequestCheckout)
            self.selectedOptions.push("specialRequestCheckout");
          self.partner = response.data;
          self.logoSrc = self.partner.logo;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    save() {
      let self = this;
      let form = new FormData();
      form.append("file", self.file);
      if (self.selectedOptions.find((option) => option === "canModify"))
        form.append("customerCanModifyBookings", true);
      else form.append("customerCanModifyBookings", false);
      if(self.selectedOptions.find(option => option === "specialRequestCheckout"))
        form.append("specialRequestCheckout", true);
      else form.append("specialRequestCheckout", false);
      if (self.selectedOptions.find((option) => option === "canCancel"))
        form.append("customerCanCancelBookings", true);
      else form.append("customerCanCancelBookings", false);
      axios
        .post(`${this.$apiAdress}/v1/Partner/update-generic`, form)
        .then(function (response) {
          self.partner = response.data;
          self.logoSrc = self.partner.logo;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated account.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.get();
  },
};
</script>
<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>